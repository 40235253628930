<template>
  <product title="Amazfit GTR 钢铁侠系列限量版"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1399"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/Jy81Kh"
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr-iron',
  components: {
    Product
  },
  data () {
    return {
      slogan: '独占金色不锈钢金属机身 | 钢铁侠专属定制红色表带<br/>专属定制表盘 | 视网膜级绚丽显示屏·全天候显示 | 24 天强劲续航<br/>AI 自动甄别心律不齐（含房颤）| 50 米防水 | 12 种运动模式<br/>百城公交 | 模拟门卡',
      colors: [
        '钢铁侠版'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/gtr-iron/swiper/1.png',
          CDN_URL + '/images/product/gtr-iron/swiper/2.png',
          CDN_URL + '/images/product/gtr-iron/swiper/3.png',
          CDN_URL + '/images/product/gtr-iron/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/gtr-iron/detail/s01.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s02.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s03.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s04.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s05.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s06.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s07.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s08.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s09.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s10.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s10_1.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s12.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s13.jpg',
        CDN_URL + '/images/product/gtr-iron/detail/s14.jpg',
        CDN_URL + '/images/common/zepp-black.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/gtr-iron/detail/s16.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
